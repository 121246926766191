import { render, staticRenderFns } from "./tag-details.vue?vue&type=template&id=752969de&scoped=true"
import script from "./tag-details.controller.js?vue&type=script&lang=js&external"
export * from "./tag-details.controller.js?vue&type=script&lang=js&external"
import style0 from "./_tag-details.scss?vue&type=style&index=0&id=752969de&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "752969de",
  null
  
)

export default component.exports
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tagModal[data-v-2ce4ad9d] .modal-header {
  display: flex;
}
.tagModal[data-v-2ce4ad9d] .modal-header .close-modal {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.tagModal[data-v-2ce4ad9d] .modal-header .tagModalHeaderAction {
  cursor: pointer;
  color: #5C5E66;
}
.tagModal[data-v-2ce4ad9d] .confirm-delete-message {
  font-size: 24px;
}
.tagModal[data-v-2ce4ad9d] .modal-title {
  padding-bottom: 0.5rem;
  text-align: left;
}
.tagModal[data-v-2ce4ad9d] .modal-title h1 {
  border-bottom: none;
  padding-bottom: 0;
}
.tagModal[data-v-2ce4ad9d] .modal-body {
  padding: 20px 15px;
}
.tagModal[data-v-2ce4ad9d] .modal-content {
  width: 500px;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./_tag-modal.scss"],"names":[],"mappings":"AAKQ;EACI,aAAA;AAJZ;AAMY;EACI,aAAA;EACA,WAAA;EACA,yBAAA;AAJhB;AAOY;EACI,eAAA;EACA,cAAA;AALhB;AASQ;EACI,eAAA;AAPZ;AAUQ;EACI,sBAAA;EACA,gBAAA;AARZ;AASY;EACI,mBAAA;EACA,iBAAA;AAPhB;AAWQ;EACI,kBAAA;AATZ;AAYQ;EACI,YAAA;EACA,YAAA;AAVZ","sourcesContent":["@import \"~vue_root/assets/scss/_variables.scss\";\n\n.tagModal {\n\n    ::v-deep {\n        .modal-header {\n            display: flex;\n\n            .close-modal {\n                display: flex;\n                width: 100%;\n                justify-content: flex-end;\n            }\n\n            .tagModalHeaderAction {\n                cursor: pointer;\n                color: #5C5E66;\n            }\n        }\n       \n        .confirm-delete-message {\n            font-size: 24px;\n        }\n\n        .modal-title {\n            padding-bottom: 0.5rem;\n            text-align: left;\n            & h1 {\n                border-bottom: none;\n                padding-bottom: 0;\n            }\n        }\n\n        .modal-body {\n            padding: 20px 15px;\n        }\n\n        .modal-content {\n            width: 500px;\n            margin: auto;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

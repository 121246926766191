import Vue from 'vue';
import selectDateRange from 'vue_root/mixins/selectDateRange.mixin.js';
import sortBankAccounts from 'vue_root/mixins/sortBankAccounts.mixin.js';
import CustomTransactionModal from 'vue_root/authorized/subscriber/components/custom-transaction-modal/custom-transaction-modal';
import PastTransactions from 'vue_root/authorized/subscriber/components/past-transactions/past-transactions';
import SplitTransactionModal from 'vue_root/authorized/subscriber/components/split-transaction-modal/split-transaction-modal';
import BulkDeleteTransactionModal from './components/bulk-delete-transaction-modal/bulk-delete-transaction-modal';
import TagModal from './components/tag-modal/tag-modal.vue';

export default {
    components: {
        CustomTransactionModal,
        PastTransactions,
        SplitTransactionModal,
        BulkDeleteTransactionModal,
        TagModal,
    },
    props: {
        displayErrorMessage: {
            type: Function,
            required: true
        },
    },
    mixins: [
        selectDateRange,
        sortBankAccounts,
    ],
    filters: {
        formatDate: function(date){
            return Vue.moment(date).format('MM/DD/YY');
        }
    },
    data,
    computed: getComputed(),
    created,
    methods: getMethods(),
    watch: getWatchHandlers(),
};

function data(){
    return {
        selectedDateRange: null,
        startDate: null,
        endDate: null,
        searchText: '',
        selectedAccountOption: { label: 'All buckets', value: null },
        selectedAssignableAccountOption: { label: 'Select a bucket', value: null },

        transactions: [],
        paginationQuery: {
            pageNum: 1,
            perPage: 50,
        },
        totalTransactionCount: 0,
        selectAllTransactions: false,
        selectedTransactions: [],
        loadingTransactions: false,

        assignedTagsByBuckets: [],
        nonTagTxByBuckets: [],
        assigningTransactions: false,
        allTxHasTagAssigned: false,

        tagsToFilter: [],
        tagsToAssign: [],
        hasHiddenTx: false, // TODO: remove this after the backend is updated
    };
}

function created(){
    const vm = this;
    vm.selectedDateRange = vm.dateRangeOptions[0];
    const userId = vm.$store.getters['user/userIdOfActiveAccount'];
    vm.$store.dispatch('tag/GET_DATA', { force: true, userId: userId });
}

function getComputed(){
    return {
        bankAccounts(){
            const vm = this;
            const hiddenPurposes = [
                'none',
                'primary_checking',
                'primary_savings',
                'unassigned',
                'credit'
            ];

            return Vue.dymUtilities.cloneObject(vm.$store.state.authorized.bankAccounts.bankAccounts).filter(({ purpose }) => !hiddenPurposes.includes(purpose));
        },
        accountSelectOptions(){
            const vm = this;
            const nullOptions = [{ label: 'All buckets', value: null }, { label: 'Unassigned', value: null }];
            const hiddenOptions = [{ label: 'Hidden', value: null, isHidden: true }];
            const options = vm.bankAccounts.map((bankAccount) => {
                return {
                    label: bankAccount.slug === 'cc_payoff' ? `${bankAccount.credit_card_account.name} Payoff` : bankAccount.name,
                    color: bankAccount.slug === 'cc_payoff' ? `${bankAccount.credit_card_account.color} Payoff` : bankAccount.color,
                    value: bankAccount
                };
            }).sort(vm.byModifiedStoreOrder);
            if(vm.hasHiddenTx){
                return nullOptions.concat(options).concat(hiddenOptions);
            }
            return nullOptions.concat(options);
        },
        assignableAccountSelectOptions(){
            const vm = this;
            const nullOptions = [{ label: 'Select a bucket', value: null }, { label: 'Unassigned', value: { id: 0 }}];
            const hiddenOptions = [{ label: 'Hidden', value: { id: 0 }, isHidden: true }];
            const options = vm.bankAccounts.map((bankAccount) => {
                return {
                    label: bankAccount.slug === 'cc_payoff' ? `${bankAccount.credit_card_account.name} Payoff` : bankAccount.name,
                    color: bankAccount.slug === 'cc_payoff' ? `${bankAccount.credit_card_account.color} Payoff` : bankAccount.color,
                    value: bankAccount
                };
            }).sort(vm.byModifiedStoreOrder);
            return nullOptions.concat(options).concat(hiddenOptions);
        },
        totalAmount(){
            const vm = this;
            const transactionsToSum = vm.selectedTransactions.length > 0 ? vm.selectedTransactions : vm.transactions;
            const total = transactionsToSum.reduce((value, transaction) => value - transaction.amount, 0);
            return total.toFixed(2);
        },
        disabledStartDates(){
            const vm = this;
            const disabledDates = {};
            disabledDates.to = Vue.moment().subtract(2, 'years').toDate();
            disabledDates.from = vm.endDate;
            return disabledDates;
        },
        disabledEndDates(){
            const disabledDates = {};
            disabledDates.to = Vue.moment().subtract(2, 'years').toDate();
            disabledDates.from = new Date();
            return disabledDates;
        },
        bankAccountsForEdit(){
            return this.$store.state.authorized.bankAccounts.bankAccounts.filter(filterInsitutionAccountsAndCreditCards);

            function filterInsitutionAccountsAndCreditCards(bankAccount){
                const hasInsitutionAccount = bankAccount.institution_account;
                const isCreditCardAccount = bankAccount.type === 'credit';
                return hasInsitutionAccount || isCreditCardAccount;
            }
        },
        linkedBankAccountsForEdit(){
            return this.bankAccountsForEdit.filter(bankAccount => !!bankAccount.institution_account);
        },
        loadPastTransactionStartDate(){
            return Vue.moment().subtract(2, 'years').toDate();
        },
        tagsAssignedToCurrentBucket(){
            const vm = this;
            if(!vm.selectedAccountOption?.value){
                return [];
            }

            const tagItems = JSON.parse(JSON.stringify(vm.assignedTagsByBuckets[vm.selectedAccountOption?.value.id] || []));
            if(tagItems.length > 0 && vm.nonTagTxByBuckets[vm.selectedAccountOption?.value.id] > 0){
                tagItems.push({ name: 'Other', id: -1 });
            }

            return tagItems;
        },
        tagsAssignableForSelectedBucket(){
            const vm = this;
            if(!vm.selectedAssignableAccountOption?.value){
                return [];
            }

            const bankAccounts = JSON.parse(JSON.stringify(vm.$store.state.tag.data));
            const bankAccountItem = bankAccounts.filter(item => item.id === vm.selectedAssignableAccountOption.value.id);
            const tagItems = bankAccountItem[0]?.tags || [];

            if(tagItems.length > 0 && (vm.assignedTagsByBuckets[vm.selectedAssignableAccountOption.value.id] || []).length > 0){
                tagItems.push({ name: 'Other', id: -1 });
            }

            return tagItems;
        },
    };
}

function getWatchHandlers(){
    return {
        selectedDateRange(){
            const vm = this;
            const dateRange = vm.getDatesByOptionValue(vm.selectedDateRange);
            if(dateRange){
                vm.startDate = dateRange[0].toDate();
                vm.endDate = dateRange[1].toDate();
            }

            vm.fetchTransactions();
        },
        selectedTransactions(newSelectedTransactions){
            const isAllSelected = newSelectedTransactions.length === this.transactions.length;
            this.selectAllTransactions = isAllSelected;
        },
    };
}

function getMethods(){
    return {
        fetchTransactions,
        isTransactionSelected,
        onChangeSelectAllTransactions,
        assignSelectedTransactions,
        handleChangePagination,
        onRefreshTransactions,
        editTransaction,
        handleSelectTransaction,
        onHoverTransactionMarking,
        onMouseLeaveTransactionMarking,
        selectingTransaction,
        clickTagItem,
        clickTagItemToAssign,
        resetTagsAndFetchTransactions,
        resetTagsToAssign,
        onAddTagForBucket,
        onTagModalClose,
    };

    function fetchTransactions(){
        const vm = this;

        const query = {
            start_date: Vue.moment(vm.startDate).format('YYYY-MM-DD'),
            end_date: Vue.moment(vm.endDate).format('YYYY-MM-DD'),
            assigned_account_id: vm.selectedAccountOption.value ? vm.selectedAccountOption.value.id : vm.selectedAccountOption.label,
            search: vm.searchText,
            tags: vm.tagsToFilter,
            ...vm.paginationQuery
        };

        const savedScrollPos = window.scrollY;
        vm.loadingTransactions = true;
        return Vue.appApi().authorized().bankAccount().loadTransactions(query)
            .then(setTransactions)
            .catch(handleLoadingError)
            .finally(resetLoadingState);

        function setTransactions({ data }){
            const { transactions, total, assignedTagsByBuckets, allTxHasTagAssigned, nonTagTxByBuckets, hasHiddenTx } = data;
            vm.transactions = transactions || [];
            vm.assignedTagsByBuckets = assignedTagsByBuckets || [];
            vm.nonTagTxByBuckets = nonTagTxByBuckets || [];
            vm.totalTransactionCount = total;
            vm.selectedTransactions = [];
            vm.allTxHasTagAssigned = allTxHasTagAssigned;
            vm.hasHiddenTx = hasHiddenTx;
            vm.$nextTick(() => {
                window.scrollTo(0, savedScrollPos);
            });
        }

        function handleLoadingError(error){
            vm.transactions = [];
            vm.displayErrorMessage(error);
        }

        function resetLoadingState(){
            vm.loadingTransactions = false;
        }
    }

    function isTransactionSelected(transactionToCheck){
        return this.selectedTransactions.some(transaction => transaction.id === transactionToCheck.id);
    }

    function onChangeSelectAllTransactions(){
        const vm = this;
        if(vm.selectAllTransactions){
            vm.selectedTransactions = Object.assign([], vm.transactions);
        } else {
            vm.selectedTransactions = [];
        }
    }

    function assignSelectedTransactions(){
        const vm = this;
        const targetBankAccount = vm.selectedAssignableAccountOption.value;
        const targetTransactionIds = vm.selectedTransactions.map(transaction => transaction.id);
        const isAssignable = targetBankAccount && targetTransactionIds.length > 0;
        if(!isAssignable){
            vm.displayErrorMessage('Please select transactions and bucket to assign.');
            return;
        }

        const payload = {
            transaction_ids: targetTransactionIds,
            tag: vm.tagsToAssign[0],
            hidden: vm.selectedAssignableAccountOption.isHidden ? 1 : 0
        };

        vm.assigningTransactions = true;
        Vue.appApi().authorized().bankAccount(targetBankAccount.id).assignment().postKeepTransaction(payload)
            .then(onAssignSuccess)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);

        function onAssignSuccess(){
            vm.$emit('assign-success');
            vm.onRefreshTransactions();
        }

        function resetLoadingState(){
            vm.selectedAssignableAccountOption = { label: 'Select a bucket', value: null };
            vm.assigningTransactions = false;
        }
    }

    function handleChangePagination(event){
        const vm = this;
        Object.assign(vm.paginationQuery, event);
        vm.fetchTransactions();
    }

    function onRefreshTransactions(){
        this.$store.dispatch('authorized/transactions/FETCH_UNASSIGNED_TRANSACTIONS');
        this.fetchTransactions();
    }

    function editTransaction(transaction){
        const vm = this;
        const isCustomTransaction = !transaction.parent_transaction_id && !transaction.remote_transaction_id;
        if(isCustomTransaction){
            vm.$refs.customTransactionModal.openModal(transaction);
        } else {
            vm.$refs.splitTransactionModal.openModal(transaction);
        }
    }

    function handleSelectTransaction(event){
        event.stopPropagation();
    }

    function onHoverTransactionMarking(id){
        const transaction = this.transactions.find((transaction) => transaction.id === id);
        if(transaction.assigned_account_color !== ''){
            this.$refs[`transactionMarking${id}`][0].classList.add('isHovering');
            this.$refs[`transactionMarking${id}`][0].textContent = transaction.assigned_account?.bank_account.name;
        } else {
            this.$refs[`transactionMarking${id}`][0].textContent = '';
        }
    }

    function onMouseLeaveTransactionMarking(id){
        this.$refs[`transactionMarking${id}`][0].classList.remove('isHovering');
        this.$refs[`transactionMarking${id}`][0].textContent = '';
    }

    function selectingTransaction(event, transaction){
        event.preventDefault();
        const transactionIndex = this.selectedTransactions.findIndex(selected => selected.id === transaction.id);
        if(transactionIndex >= 0){
            this.selectedTransactions.splice(transactionIndex, 1);
        } else {
            this.selectedTransactions.push(transaction);
        }
    }

    function clickTagItem(tag){
        const vm = this;
        if(vm.tagsToFilter.includes(tag.id)){
            vm.tagsToFilter = vm.tagsToFilter.filter(tagId => tagId !== tag.id);
        } else {
            vm.tagsToFilter.push(tag.id);
        }

        vm.fetchTransactions();
    }

    function clickTagItemToAssign(tag){
        const vm = this;
        if(vm.tagsToAssign.includes(tag.id)){
            vm.tagsToAssign = vm.tagsToAssign.filter(tagId => tagId !== tag.id);
        } else {
            vm.tagsToAssign = [tag.id];
        }
    }

    function resetTagsAndFetchTransactions(){
        const vm = this;
        vm.tagsToFilter = [];
        vm.fetchTransactions();
    }

    function resetTagsToAssign(){
        const vm = this;
        vm.tagsToAssign = [];
    }

    function onAddTagForBucket(bankAccount){
        const vm = this;
        vm.$refs.tagModal.show();
        vm.$refs.tagModal.gotoAddTagItem(bankAccount);
    }

    function onTagModalClose(){
        this.fetchTransactions();
    }
}

export default {
    props: {
        loadingTagItems: {
            type: Boolean,
        },
        bankAccountTags: {
            type: Array,
            default: [],
        },
    },
    data,
    computed: getComputed(),
    methods: getMethods(),
};

function data(){
    return {
        tagToDelete: null,
    };
}

function getComputed(){
    return {};
}

function getMethods(){
    return {
        onClickDeleteItem,
        confirmDeletion,
        onCancelDeletion,
    };

    function onClickDeleteItem(id){
        this.tagToDelete = id;
        this.$refs.confirmDeleteModal.show();
    }

    function confirmDeletion(){
        this.$emit('onDeleteItem', this.tagToDelete);
        this.tagToDelete = null;
        this.$refs.confirmDeleteModal.hide();
    }

    function onCancelDeletion(){
        this.tagToDelete = null;
        this.$refs.confirmDeleteModal.hide();
    }
}

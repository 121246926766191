import TagList from './components/tag-list/tag-list.vue';
import TagDetails from './components/tag-details/tag-details.vue';
import sortBankAccounts from 'vue_root/mixins/sortBankAccounts.mixin.js';

export default {
    components: {
        TagList,
        TagDetails,
    },
    props: {
        bankAccounts: {
            type: Array,
            required: true,
        },
    },
    data,
    mixins: [sortBankAccounts],
    computed: getComputed(),
    watch: getWatchers(),
    methods: getMethods(),
};

function data(){
    return {
        bankAccountTags: [],
        isModalShown: false,
        isDisplayingList: true,
        activeTagItem: null,
        loadingTagItems: true,
        isFormEditting: false,
    };
}

function getComputed(){
    return {};
}

function getWatchers(){
    return {
        isModalShown: {
            handler: function(newVal){
                const vm = this;
                if(newVal === true){
                    vm.fetchTagItems();
                }
            },
            immediate: true,
        },
        loadingTagItems: {
            handler: function(newVal){
                const vm = this;
                vm.$emit('loading', newVal);
            },
            immediate: true
        },
    };
}

function getMethods(){
    return {
        show,
        onModalClose,
        confirmUnsavedChanges,
        fetchTagItems,
        onClickBackToList,
        setTagItems,
        onClickTagItem,
        onDeleteItem,
        gotoAddTagItem,
    };

    function show(){
        const vm = this;
        vm.$refs.tagModal.show();
    }

    function onModalClose(){
        this.isFormEditting = false;
        this.isModalShown = false;
        this.isDisplayingList = true;
        this.activeTagItem = null;
        this.bankAccountTags = [];
        this.loadingTagItems = true;
        this.$refs.tagModal.hide();
        this.$emit('modal-close');
    }

    function confirmUnsavedChanges(event){
        const vm = this;
        if(vm.isFormEditting){
            event.preventDefault();
            return confirmCloseChanges().then(answerConfirmation);
        } else {
            vm.onModalClose();
        }
        function confirmCloseChanges(){
            const message = 'You have unsaved changes, are you sure you want to close?';
            const options = {
                size: 'sm',
                okVariant: 'light',
                cancelVariant: 'light',
                okTitle: 'Close',
                cancelTitle: 'Go back',
                hideHeader: true,
                bodyClass: 'text-center mb-0 mt-3',
                footerClass: 'd-flex justify-content-center border-0',
                centered: true,
            };
            return vm.$bvModal.msgBoxConfirm(message, options);
        }

        function answerConfirmation(isConfirmed){
            if(isConfirmed){
                vm.onModalClose();
            }
        }
    }

    function fetchTagItems(){
        const vm = this;
        vm.loadingTagItems = true;
        const userId = vm.$store.getters['user/userIdOfActiveAccount'];
        this.$store.dispatch('tag/GET_DATA', { force: true, userId: userId }).then(vm.setTagItems).finally(resetLoadingState);

        function resetLoadingState(){
            vm.loadingTagItems = false;
        }
    }

    function setTagItems(backToList = false){
        const vm = this;
        const items = JSON.parse(JSON.stringify(vm.$store.state.tag.data));
        if(items !== undefined){
            vm.bankAccountTags = items.sort(vm.byModifiedStoreOrder);
        }
        vm.isFormEditting = false;
        if(backToList){
            vm.isDisplayingList = true;
            vm.activeTagItem = null;
        }
    }

    function onClickBackToList(){
        const vm = this;
        vm.isDisplayingList = true;
        vm.activeTagItem = null;
        vm.isFormEditting = false;
    }

    function onClickTagItem(item){
        const vm = this;
        vm.activeTagItem = {
            id: item.tag.id,
            name: item.tag.name,
            isDirty: false,
            bucket: {
                id: item.bankAccount.id,
                slug: item.bankAccount.slug,
                color: item.bankAccount.color,
                icon: item.bankAccount.icon,
                label: item.bankAccount.name,
            }
        };
        vm.isDisplayingList = false;
    }

    function gotoAddTagItem(bankAccount){
        const vm = this;
        vm.activeTagItem = {
            id: null,
            name: null,
            isDirty: false,
            bucket: {
                id: bankAccount.id,
                slug: bankAccount.slug,
                color: bankAccount.color,
                icon: bankAccount.icon,
                label: bankAccount.name,
            }
        };
        vm.isDisplayingList = false;
    }

    function onDeleteItem(id){
        const vm = this;
        Vue.appApi().authorized().tag().deleteTag(id).then(vm.fetchTagItems);
    }
}

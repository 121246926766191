import guest from './modules/guest.store';
import authorized from './modules/authorized.store';
import tourWalkthrough from './modules/tour-walkthrough.store';
import renderView from './modules/render-view.store';
import creditCardOverviews from './modules/services/credit-card-overviews.store.js';
import subscriptionTypes from './modules/services/subscription-types.store.js';
import incomeAccountOverview from './modules/services/income-account-overview.store.js';
import allocationAccounts from './modules/services/allocation-accounts.store.js';
import payoffAccountOverview from './modules/services/payoff-account-overview.store.js';
import assignableAccounts from './modules/services/assignable-accounts.store.js';
import savingsCC from './modules/services/savings-cc.store.js';
import scheduleItem from './modules/services/schedule-item.store.js';
import tag from './modules/services/tag.store.js';
import debugLogs from './modules/debug-logs.store.js';

const debug = process.env.NODE_ENV !== 'production';

export default {
    modules: {
        guest,
        authorized,
        tourWalkthrough,
        renderView,
        creditCardOverviews,
        subscriptionTypes,
        incomeAccountOverview,
        allocationAccounts,
        payoffAccountOverview,
        assignableAccounts,
        savingsCC,
        scheduleItem,
        debugLogs,
        tag,
    },
    state: {
        showIosLoginOverlay: false
    },
    mutations: getMutations(),
    getters: getGetters(),
    strict: debug,
};

function getMutations(){
    return {
        SET_SHOW_IOS_OVERLAY: setShowIosOverlay
    };

    function setShowIosOverlay(state, payload){
        state.showIosLoginOverlay = payload;
    }
}

function getGetters(){
    return {
        user,
        freeTrialUsed,
        isInDemoMode
    };

    function user(state){
        return state.guest.user.user;
    }

    function freeTrialUsed(state, getters){
        const user = getters.user;
        const isPlusAccount = user && user.current_account.subscription_plan === 'plus';
        const accountHasPaymentOnFile = user && user.current_account.is_trial_used;
        return isPlusAccount || accountHasPaymentOnFile;
    }

    function isInDemoMode(state, getters){
        const user = getters.user;
        return user && user.current_account && user.current_account.status === 'demo';
    }
}

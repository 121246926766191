export default {
    props: {
        activeTagItem: {
            type: Object,
            default: null,
        },
    },
    data,
    created,
    watch: getWatchers(),
    computed: getComputed(),
    methods: getMethods(),
};

function data(){
    return {
        tagItemForm: {
            name: '',
            bucket: { label: 'Select bucket', value: null },
            id: null,
            isDirty: false,
        },
        isSaving: false,
        isDeleting: false,
        errorMessages: [],
        isConfirmingDeletion: false,
    };
}

function created(){}

function getComputed(){
    return {
        bankAccounts(){
            const vm = this;
            const hiddenPurposes = [
                'none',
                'primary_checking',
                'primary_savings',
                'unassigned',
                'credit'
            ];

            return Vue.dymUtilities.cloneObject(vm.$store.state.authorized.bankAccounts.bankAccounts).filter(filterByParentAndPurpose);
            function filterByParentAndPurpose(bankAccount){
                const { purpose, parent_bank_account_id } = bankAccount;
                const checkParent = !vm.parentBankAccount || parent_bank_account_id === vm.parentBankAccount.id;
                const checkCreditAccount = bankAccount.purpose === 'cc_payoff' ? bankAccount.credit_card_account.appears_in_account_list : true;
                return !hiddenPurposes.includes(purpose) && checkParent && checkCreditAccount;
            }
        },
        selectOptions(){
            const vm = this;
            const nullOptions = [{ label: 'Select bucket', value: null }];
            const options = vm.bankAccounts.sort(vm.byModifiedStoreOrder).map((bankAccount) => {
                return {
                    color: bankAccount.slug === 'cc_payoff' ? bankAccount.credit_card_account.color : bankAccount.color,
                    icon: bankAccount.slug === 'cc_payoff' ? bankAccount.credit_card_account.icon : bankAccount.icon,
                    label: bankAccount.slug === 'cc_payoff' ? `${bankAccount.credit_card_account.name} Payoff` : bankAccount.name,
                    value: bankAccount
                };
            });
            return nullOptions.concat(options);
        },
    };
}

function getWatchers(){
    return {
        activeTagItem: {
            deep: true,
            immediate: true,
            handler: function(newVal){
                const vm = this;
                if(newVal){
                    vm.tagItemForm = Object.assign({}, newVal);
                }
            },
        },
    };
}

function getMethods(){
    return {
        onEdittingForm,
        saveTagItem,
        refreshList,
        displayErrorMessage,
        onDeleteItem,
    };

    function onEdittingForm(){
        this.tagItemForm.isDirty = true;
        this.$emit('onFormEdit');
    }

    function saveTagItem(){
        const vm = this;
        if(!vm.activeTagItem && !vm.tagItemForm.bucket.value){
            vm.errorMessages.push('Please select a bucket.');
            return;
        }
        const userId = vm.$store.getters['user/userIdOfActiveAccount'];
        const payload = {
            bank_account_id: vm.activeTagItem ? vm.tagItemForm.bucket.id : vm.tagItemForm.bucket.value.id,
            name: vm.tagItemForm.name,
            user_id: userId,
        };

        vm.isSaving = true;
        if(vm.activeTagItem?.id){
            return Vue.appApi().authorized().tag().update(vm.activeTagItem.id, payload)
                .then(vm.refreshList)
                .catch(vm.displayErrorMessage)
                .finally(clearFlags);
        }
        return Vue.appApi().authorized().tag().store(payload)
            .then(vm.refreshList)
            .catch(vm.displayErrorMessage)
            .finally(clearFlags);

        function clearFlags(){
            vm.isSaving = false;
        }
    }

    function refreshList(){
        const vm = this;
        const userId = vm.$store.getters['user/userIdOfActiveAccount'];
        return vm.$store.dispatch('tag/GET_DATA', { force: true, userId: userId })
            .then(() => vm.$emit('saveTagItem'))
            .catch(vm.displayErrorMessage);
    }

    function displayErrorMessage(error){
        const vm = this;
        if(error.appMessage){
            vm.errorMessages.push(error.appMessage);
        }
    }

    function onDeleteItem(id){
        const vm = this;
        Vue.appApi().authorized().tag().deleteTag(id)
            .then(vm.refreshList)
            .catch(vm.displayErrorMessage);
    }
}

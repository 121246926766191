import { render, staticRenderFns } from "./tag-modal.vue?vue&type=template&id=2ce4ad9d&scoped=true"
import script from "./tag-modal.controller.js?vue&type=script&lang=js&external"
export * from "./tag-modal.controller.js?vue&type=script&lang=js&external"
import style0 from "vue_root/assets/scss/_table-input-modal.scss?vue&type=style&index=0&id=2ce4ad9d&prod&lang=scss&scoped=true&external"
import style1 from "./_tag-modal.scss?vue&type=style&index=1&id=2ce4ad9d&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ce4ad9d",
  null
  
)

export default component.exports
import Vue from 'vue';
import selectDateRange from 'vue_root/mixins/selectDateRange.mixin.js';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';

export default {
    mixins: [
        selectDateRange,
    ],
    data,
    created,
    watch: getWatcher(),
    computed: getComputed(),
    methods: getMethods(),
};

function data(){
    return {
        selectedDateRange: null,
        startDate: Vue.moment(),
        endDate: Vue.moment(),
        incomeAccount: {},
        checkingSubAccounts: [],
        errorMessages: [],
        validationErrors: {},
        downloadingPDF: false,
        isIOS: window.appEnv.clientPlatform === 'ios',

        expenseItems: [],
        incomeItems: [],
    };
}

function created(){
    const defaultDateRange = this.$route.query.date_range;
    this.selectedDateRange = defaultDateRange !== undefined ? defaultDateRange : this.dateRangeOptions[0];
    this.$store.dispatch('tag/GET_DATA');
}

function getWatcher(){
    return {
        selectedDateRange(){
            const vm = this;
            const dateRange = vm.getDatesByOptionValue(vm.selectedDateRange);
            if(dateRange){
                vm.startDate = dateRange[0].toDate();
                vm.endDate = dateRange[1].toDate();
            }

            vm.fetchAssignmentsAggregate();
        },
        '$store.state.authorized.bankAccounts.bankAccounts'(){
            this.fetchAssignmentsAggregate();
        },
        '$store.state.tag.data'(){
            this.fetchAssignmentsAggregate();
        },
    };
}

function getComputed(){
    return {
        userName(){
            return this.$store.state.guest.user.user.name;
        },
        selectedExpensesAmount(){
            return this.expenseItems.reduce((total, subAccount) => subAccount.showBucket ? subAccount.amountAssigned + total : total, 0);
        },
        selectedIncomeAmount(){
            return this.incomeItems.reduce((total, subAccount) => subAccount.showBucket ? subAccount.amountAssigned + total : total, 0);
        },
        summaryAmount(){
            const summaryAmount = this.selectedIncomeAmount + this.selectedExpensesAmount;
            if(summaryAmount < 0){
                return {
                    label: 'Loss',
                    amount: summaryAmount,
                };
            } else if(summaryAmount > 0){
                return {
                    label: 'Profit',
                    amount: summaryAmount,
                };
            } else {
                return null;
            }
        },
        allBankAccounts(){
            return [this.incomeAccount, ...this.checkingSubAccounts];
        },
        tagsMap(){
            const vm = this;
            const bankAccounts = JSON.parse(JSON.stringify(vm.$store.state.tag.data));
            const tagsByBankAccounts = bankAccounts.map(bankAccount => bankAccount.tags || []);
            const tagsMap = {};
            tagsByBankAccounts.forEach(tags => {
                tags.forEach(tag => {
                    tagsMap[tag.id] = tag;
                });
            });

            return tagsMap;
        },
    };
}

function getMethods(){
    return {
        fetchAssignmentsAggregate,
        displayErrorMessage,
        resetAccounts,
        setBankAccountAmountAssigned,
        getPercentAmongTotalAssignments,
        downloadPDF,
    };

    function fetchAssignmentsAggregate(){
        const vm = this;
        vm.resetAccounts();
        const bankAccountIds = vm.allBankAccounts.map(subAccount => subAccount.id);

        const query = {
            start_date: Vue.moment(vm.startDate).format('YYYY-MM-DD'),
            end_date: Vue.moment(vm.endDate).format('YYYY-MM-DD'),
            bank_account_ids: bankAccountIds
        };

        vm.loadingAssignments = true;
        return Vue.appApi().authorized().account().reports().getAssignmentsAggregate(query)
            .then(setAssignments)
            .catch(handleLoadingError)
            .finally(resetLoadingState);

        function setAssignments({ data }){
            vm.aggregateData = data;
            vm.expenseItems.length = 0;
            vm.incomeItems.length = 0;
            vm.allBankAccounts.forEach(vm.setBankAccountAmountAssigned);

            vm.expenseItems.sort((a, b) => {
                if(a.name.startsWith('Other -')){
                    return 1;
                } else if(b.name.startsWith('Other -')){
                    return -1;
                }
                return a.name.localeCompare(b.name);
            });
            vm.incomeItems.sort((a, b) => {
                if(a.name.startsWith('Other -')){
                    return 1;
                } else if(b.name.startsWith('Other -')){
                    return -1;
                }
                return a.name.localeCompare(b.name);
            });
        }

        function handleLoadingError(error){
            vm.assignments = [];
            vm.displayErrorMessage(error);
        }

        function resetLoadingState(){
            vm.loadingAssignments = false;
        }
    }

    function resetAccounts(){
        const vm = this;
        resetIncomeAccount();
        resetCheckingSubAccounts();

        function resetIncomeAccount(){
            const localCopyOfIncomeAccount = Vue.dymUtilities.cloneObject(vm.$store.state.authorized.bankAccounts.bankAccounts)
                .filter(bankAccount => bankAccount.purpose === 'income')[0];
            if(!localCopyOfIncomeAccount){
                vm.incomeAccount = {};
                return;
            }

            localCopyOfIncomeAccount.amountAssigned = 0;
            localCopyOfIncomeAccount.showBucket = true;

            vm.incomeAccount = localCopyOfIncomeAccount;
        }

        function resetCheckingSubAccounts(){
            const primaryCheckingAccount = vm.$store.state.authorized.bankAccounts.bankAccounts.filter(bankAccount => bankAccount.slug === 'primary_checking')[0];
            if(!primaryCheckingAccount){
                vm.checkingSubAccounts = [];
                return;
            }

            const hiddenPurpose = ['cc_payoff', 'income'];
            const localCopyOfCheckingSubAccounts = Vue.dymUtilities.cloneObject(primaryCheckingAccount.sub_accounts.filter(subAccount => !hiddenPurpose.includes(subAccount.purpose)));
            vm.checkingSubAccounts = localCopyOfCheckingSubAccounts.map(subAccount => {
                subAccount.amountAssigned = 0;
                subAccount.showBucket = true;
                return subAccount;
            });
        }
    }

    function setBankAccountAmountAssigned(bankAccount){
        const vm = this;
        if(bankAccount && vm.aggregateData && vm.aggregateData.data[bankAccount.id]){
            const isIncome = bankAccount.purpose === 'income';

            bankAccount.amountAssigned = vm.aggregateData.data[bankAccount.id]?.total || 0;
            bankAccount.amountAssignedPerTag = {};
            const aggregateDataPerTag = vm.aggregateData.data[bankAccount.id]?.tags || {};
            Object.keys(aggregateDataPerTag).forEach(tagId => {
                if(tagId === ''){
                    bankAccount.amountAssignedPerTag[`Other - ${bankAccount.name}`] = aggregateDataPerTag[tagId];
                } else if(vm.tagsMap[tagId]){
                    bankAccount.amountAssignedPerTag[vm.tagsMap[tagId].name] = aggregateDataPerTag[tagId];
                }
            });

            const tagsAvailable = Object.keys(bankAccount.amountAssignedPerTag);
            const hasValidTags = tagsAvailable.length > 0 && !tagsAvailable[0].startsWith('Other -');
            if(hasValidTags){
                if(isIncome){
                    tagsAvailable.forEach(tag => {
                        vm.incomeItems.push({
                            name: tag,
                            amountAssigned: bankAccount.amountAssignedPerTag[tag],
                            showBucket: true,
                        });
                    });
                } else {
                    tagsAvailable.forEach(tag => {
                        vm.expenseItems.push({
                            name: tag,
                            amountAssigned: bankAccount.amountAssignedPerTag[tag],
                            showBucket: true,
                        });
                    });
                }
            } else {
                if(isIncome){
                    vm.incomeItems.push({
                        name: bankAccount.name,
                        amountAssigned: bankAccount.amountAssigned,
                        showBucket: true,
                    });
                } else {
                    vm.expenseItems.push({
                        name: bankAccount.name,
                        amountAssigned: bankAccount.amountAssigned,
                        showBucket: true,
                    });
                }
            }
        }
    }

    function getPercentAmongTotalAssignments(bankAccount){
        const vm = this;
        const totalAbsExpenseAmount = vm.checkingSubAccounts.reduce((total, subAccount) => Math.abs(subAccount.amountAssigned) + total, 0);
        if(totalAbsExpenseAmount <= 0){
            return 0;
        }

        const percent = Math.abs(bankAccount.amountAssigned) * 100 / totalAbsExpenseAmount;
        return percent.toFixed(0);
    }

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error && error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function downloadPDF(){
        const vm = this;
        const payload = {
            start_date: Vue.moment(this.startDate).format('YYYY-MM-DD'),
            end_date: Vue.moment(this.endDate).format('YYYY-MM-DD'),
            income_accounts: this.incomeItems.filter(item => item.showBucket),
            expenses_accounts: this.expenseItems.filter(item => item.showBucket),
            total_income: this.selectedIncomeAmount,
            total_expenses: this.selectedExpensesAmount,
            summary_account: this.summaryAmount
        };

        vm.downloadingPDF = true;
        Vue.appApi().authorized().bankAccount().downloadProfitLossPDF(payload).then(downloadFile);

        async function downloadFile(response){
            if(vm.isIOS){
                const blob = new Blob([response.data], { type: 'application/pdf' });
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = async function(){
                    var base64data = reader.result;
                    const filename = 'Proft and Loss Statement.pdf';
                    await Filesystem.writeFile({
                        path: filename,
                        data: base64data,
                        directory: Directory.Documents,
                    });

                    const { uri } = await Filesystem.getUri({
                        path: filename,
                        directory: Directory.Documents,
                    });

                    const fileOpenerOptions = {
                        filePath: uri,
                        contentType: 'application/pdf',
                        openWithDefault: true,
                    };
                    await FileOpener.open(fileOpenerOptions);

                    vm.downloadingPDF = false;
                };
            } else {
                const blob = new Blob([response.data], { type: 'application/pdf' });

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Proft and Loss Statement.pdf';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                vm.downloadingPDF = false;
            }
        }
    }
}
